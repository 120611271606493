export default {
  breadcrumb: {
    list: 'クライアント一覧',
    create: 'クライアント登録',
    edit: 'クライアント変更',
  },
  label: {
    merchant_code: '加盟店コード(核店)',
    client_code: 'クライアントコード',
    client_name: 'クライアント名',
    client_english_name: 'クライアント英語名',
    system: 'システム',
    id_4_digit: 'ID頭4桁',
    id_digit: 'ID桁数',
    card_digit: 'カード桁数',
    id_spec: 'ID仕様',
    cd_spec: 'CD仕様',
    code_type: 'コードタイプ',
    point_up_file_dest: 'ポイントアップファイル送信先',
    api_link_dest: 'API連携先',
    test_cards: 'テストカード',
    api_secret: 'APIシークレット',
    member_ship_number_position: '会員番号開始位置',
    member_ship_number_digit: '会員番号桁数(CD含む)',
    company_code_position: '企業コード開始位置',
    company_code_digit: '企業コード桁数',
    barcode_type: 'コードタイプ',
    card_specs: 'Card仕様:',
    protocol: 'プロトコル',
    host: 'ホスト',
    user: 'ユーザー',
    password: 'パスワード',
    port: 'ポート',
    cd_formula: 'C/D計算式',
    cd_start: 'CD開始位置',
    cd_num_digits: 'CD桁数',
    company_code_start_digit: '企業コード開始位置',
    qr_code: 'QRコード',
    path: 'パス',
    file_name: 'ファイル名',
    prefix: 'Prefix',
    company_code: '企業コード',
    send_receive: '送受信',
    point_up_time: '時間',
    send_file_to_file_server: 'ファイルサーバーへファイル送信',
    select_send_file_to_file_server: 'ファイルサーバーへファイル送信を選択',
  },
  checkbox: {
    use_current_date: '現在の日付をフォルダ名として使用',
  },
  placeholder: {
    system_select: 'システム選択',
    cd_formula_select: 'CD選択',
    send_receive_select: '送受信選択',
    protocol_select: 'プロトコル選択',
    send_file_to_file_server: 'ファイルサーバーへファイル送信選択',
  },
  radio: {
    individual: '単独',
    range: '範囲',
  },
  button: {
    register: 'クライアント登録',
  },
  table: {
    client_code: 'クライアントコード',
    client_name: 'クライアント名',
    system_name: 'システム名',
    merchant_code: '加盟店コード(核店)',
  },
  modal: {
    delete: {
      message: 'こちらのクライアントを削除してもよろしいですか。',
    },
  },
};
